<template>
  <v-data-table
    locale="fr-FR"
    :headers="filteredHeader"
    :items="items"
    @click:row="clicked"
    item-key="id"
    class="elevation-1"
    :mobile-breakpoint="this.$vuetify.breakpoint.thresholds.md"
    id="assetsTable"
    :items-per-page="1000"
    hide-default-footer
    :key="assetsKey"
  >
    <template v-slot:item="{ item, isMobile }">
      <mobile-row-asset
        :item="item"
        :headers="mobHeader"
        v-if="isMobile"
        rtl
        ignore-null-val
        :class="{ 'asset-level': item.level === 4 || item.level === 3 }"
        @expand="expandRow"
        :is-expanded="shoulExpand(item.id)"
        @n-expand="removeFromExpanded"
        @row-clicked="clicked"
      >
        <template v-slot:events="{ item }">
          <div
            class="incident description"
            v-if="item.events"
            v-html="item.events"
          ></div>
        </template>
      </mobile-row-asset>
      <c-row
        v-else
        :item="item"
        :headers="filteredHeader"
        rtl
        :class="{ 'asset-level': item.level === 4 || item.level === 3 }"
        ignore-null-val
        @click="clicked(item)"
      >
        <template v-slot:events="{ item }">
          <div
            class="incident description"
            v-if="item.events"
            v-html="item.events"
          ></div>
        </template>
      </c-row>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import { permissions, remove } from "../utils";
import MobileRowAsset from "../components/MobileRowAsset";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      expanded: []
    };
  },
  components: { MobileRowAsset },
  computed: {
    ...mapGetters(["getUserProfiles", "getTicketsTasks", "assetsKey"]),
    filteredHeader() {
      let headers = [
        {
          text: this.$t("header.name"),
          align: "center",
          sortable: false,
          value: "label",
          class: "primary white--text"
        }
      ];

      if (
        this.getUserProfiles &&
        permissions.profilePermission("generique", this.getUserProfiles)
      ) {
        headers.push(
          /*{
            text: this.$t("subHeader.area"),
            align: "end",
            sortable: false,
            value: "surfacemtwofield",
            class: "border-r-0 primary white--text",
            isNumber: true
          },
          {
            text: this.$t("subHeader.tenant"),
            align: "center",
            sortable: false,
            value: "nomcommercialfield",
            class: "border-l-0 primary white--text"
          },
          {
            text: this.$t("subHeader.location"),
            align: "left",
            sortable: false,
            value: "etageportefield",
            class: "border-l-0 primary white--text"
          }*/
          {
            text: this.$t("subHeader.location"),
            align: "left",
            sortable: false,
            value: "town",
            class: "border-l-0 primary white--text"
          }
        );
      }

      if (
        this.getUserProfiles &&
        permissions.profilePermission("engagement", this.getUserProfiles) &&
        !permissions.profilePermission("financier", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: this.$t("subHeader.entry_date"),
            align: "center",
            sortable: false,
            value: "datedbutdebailfield",
            class: "primary white--text"
          },
          {
            text: this.$t("subHeader.release_date"),
            align: "center",
            sortable: false,
            value: "datefindebailfield",
            class: "border-l-0 primary white--text"
          }
        );
      }
      /* dans le tableau des assets
      if (
        this.getUserProfiles &&
        permissions.profilePermission("financier", this.getUserProfiles)
      ) {
        headers.push(
          {
            text: this.$t("subHeader.rents"),
            align: "end",
            sortable: false,
            value: "loyerannuelhtfield",
            class: "primary white--text",
            isNumber: true
          },
          {
            text: this.$t("subHeader.loads"),
            align: "end",
            sortable: false,
            value: "charge",
            class: "border-l-0 primary white--text",
            isNumber: true
          },
          {
            text: this.$t("subHeader.property_taxes_s"),
            align: "end",
            sortable: false,
            value: "impot",
            class: "border-l-0 primary white--text",
            isNumber: true
          }
        );
      }
*/
      headers.push(
        {
          text: this.$t("subHeader.tx_year_minus_1"),
          align: "end",
          sortable: false,
          value: "travaux1",
          class: "primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.tx_year"),
          align: "end",
          sortable: false,
          value: "investissement",
          class: "border-l-0 primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.tx_year_plus_1"),
          align: "end",
          sortable: false,
          value: "travaux2",
          class: "border-l-0 primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.processing_incidents"),
          align: "end",
          sortable: false,
          value: "tickets",
          class: "primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.resolved_incidents"),
          align: "end",
          sortable: false,
          value: "technique",
          class: "border-l-0 primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.event"),
          align: "center",
          sortable: false,
          value: "events",
          class: "primary white--text"
        }
      );
      return headers;
    },
    mobHeader() {
      let mobheaders = [
        {
          text: this.$t("header.name"),
          align: "center",
          sortable: false,
          value: "label",
          class: "primary white--text"
        }
      ];
      if (
        this.getUserProfiles &&
        permissions.profilePermission("generique", this.getUserProfiles)
      ) {
        mobheaders.push(
          {
            text: this.$t("subHeader.area"),
            align: "end",
            sortable: false,
            value: "surfacemtwofield",
            class: "border-r-0 primary white--text",
            isNumber: true
          },
          {
            text: this.$t("subHeader.tenant"),
            align: "center",
            sortable: false,
            value: "nomcommercialfield",
            class: "border-l-0 primary white--text"
          },
          {
            text: this.$t("subHeader.location"),
            align: "left",
            sortable: false,
            value: "etageportefield",
            class: "border-l-0 primary white--text"
          }
        );
      }
      if (
        this.getUserProfiles &&
        permissions.profilePermission("engagement", this.getUserProfiles)
      ) {
        mobheaders.push(
          {
            text: this.$t("subHeader.entry_date"),
            align: "center",
            sortable: false,
            value: "datedbutdebailfield",
            class: "primary white--text"
          },
          {
            text: this.$t("subHeader.release_date"),
            align: "center",
            sortable: false,
            value: "datefindebailfield",
            class: "border-l-0 primary white--text"
          }
        );
      }
      if (
        this.getUserProfiles &&
        permissions.profilePermission("financier", this.getUserProfiles)
      ) {
        mobheaders.push(
          {
            text: this.$t("subHeader.rents"),
            align: "center",
            sortable: false,
            value: "loyerannuelhtfield",
            class: "primary white--text",
            isNumber: true
          },
          {
            text: this.$t("subHeader.loads"),
            align: "center",
            sortable: false,
            value: "charge",
            class: "border-l-0 primary white--text",
            isNumber: true
          },
          {
            text: this.$t("subHeader.property_taxes_s"),
            align: "center",
            sortable: false,
            value: "impot",
            class: "border-l-0 primary white--text",
            isNumber: true
          }
        );
      }
      mobheaders.push(
        {
          text: this.$t("subHeader.year_minus_1"),
          align: "center",
          sortable: false,
          value: "travaux1",
          class: "primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.year"),
          align: "center",
          sortable: false,
          value: "investissement",
          class: "border-l-0 primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.year_minus_1"),
          align: "center",
          sortable: false,
          value: "travaux2",
          class: "border-l-0 primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.processing_incidents"),
          align: "center",
          sortable: false,
          value: "tickets",
          class: "primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.resolved_incidents"),
          align: "center",
          sortable: false,
          value: "technique",
          class: "border-l-0 primary white--text",
          isNumber: true
        },
        {
          text: this.$t("subHeader.event"),
          align: "center",
          sortable: false,
          value: "events",
          class: "primary white--text"
        }
      );
      return mobheaders;
    },
    filteredItems() {
      return this.items.filter(item => item.partiescommunefield !== 1);
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    clicked: function(row) {
      if (!row.name) {
        return;
      } else if (row.level === 5) {
        this.$router.push({ name: "lots.show", params: { id: row.id } });
      } else if (row.level === 4) {
        this.$router.push({ name: "assets.show", params: { id: row.id } });
      } else {
        this.$router.push({
          name: "assets.index",
          params: { assetID: row.id }
        });
      }
    },
    expandRow(item) {
      this.expanded.push(item);
    },
    shoulExpand(id) {
      return this.expanded.includes(id);
    },
    removeFromExpanded(id) {
      this.expanded = remove(this.expanded, r => r.id === id);
    }
  }
};
</script>
