<template>
  <v-card
    v-if="!isNullRow"
    elevation="12"
    class="my-2 mx-2"
    @click="rowClicked($event, item)"
  >
    <v-card-title class="subheading font-weight-bold"
      ><template v-if="isMobile"
        >{{ item.name.slice(0, 14) }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="isExpanded"
          fab
          dark
          small
          @click.prevent="removeFromExtand(item)"
          ><v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn v-else fab small color="primary" @click.prevent="expandRow(item)"
          ><v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <template v-else
        >{{ item.name }} - {{ item.surfacemtwofield }} m2 -
        {{ item.nomcommercialfield }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="isExpanded"
          fab
          dark
          small
          @click.prevent="removeFromExtand(item)"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn v-else fab small @click.prevent="expandRow(item)" color="primary"
          ><v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn></template
      >
    </v-card-title>

    <v-divider></v-divider>

    <v-list dense v-if="isExpanded">
      <v-list-item v-for="header in headers" :key="header.value">
        <v-list-item-content>{{ header.text }}:</v-list-item-content>
        <v-list-item-content class="align-end">
          <slot :name="header.value" :item="item" :header="header">
            <template v-if="isNumber(header, item[header.value])">
              {{ item[header.value] | number_format }}
            </template>
            <template v-else>
              {{ item[header.value] }}
            </template>
          </slot>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
// CCA commence à fonctionner pb e,core avec expand mais au pire on supprime 1/8 16h15
import { getObjectValueByPath } from "../utils";

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isNullRow() {
      return !getObjectValueByPath(this.item, this.headers[0].value);
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 400;
    }
  },
  methods: {
    expandRow(row) {
      this.$emit("expand", row.id);
    },
    removeFromExtand(row) {
      this.$emit("n-expand", row.id);
    },
    rowClicked(e, item) {
      if (e.target.tagName === "DIV") this.$emit("row-clicked", item);
    },
    isNumber(header, value) {
      return header.isNumber && !isNaN(value);
    }
  }
};
</script>
