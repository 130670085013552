<template>
  <v-container>
    <template v-if="isInfo">
      <v-card>
        On peut aussi personnaliser ça
      </v-card>
    </template>
    <template v-if="isMobile">
      <v-card flat width="98%" class="mb-6">
        <map-view
          :list="[{ ...latLng, name: currentAsset ? currentAsset.name : '' }]"
          :key="$route.params.id"
        ></map-view>
      </v-card>
      <v-card width="100%">
        <v-carousel
          cycle
          height="330"
          hide-delimiter-background
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="slide in images"
            :key="slide.id"
            :src="slide.image"
          ></v-carousel-item>
        </v-carousel>
      </v-card>
    </template>
    <template v-else>
      <v-row class="mb-6" no-gutters>
        <v-col md="5">
          <v-card class="ml-12" outlined tile>
            <map-view :list="locationList"></map-view>
          </v-card>
        </v-col>
        <v-col md="6" offset-md="1">
          <v-card class="mr-12" outlined tile>
            <v-carousel
              cycle
              height="330"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="slide in images"
                :key="slide.id"
                :src="slide.image"
              ></v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="isMobile">
      <v-row no-gutters class="mx-n4">
        <v-col cols="12">
          <v-card class="mt-6">
            <v-card-title class="headline display-1">
              {{ $t("tableTitle.list_lots") }}
            </v-card-title>
            <asset-table :items="currentLots"></asset-table>
          </v-card>
        </v-col>
      </v-row>
      <div style="text-align: right; font-size: small;">
        {{ $t("assetDetails.text1") }}
      </div>
    </template>
    <template v-else>
      <v-row align="center" justify="center" class="mt-4">
        <v-col cols="12">
          <v-card v-if="isCcct">
            <v-card-title>
              Liste des équipements
            </v-card-title>
            <asset-table-ccct :items="currentLots"></asset-table-ccct>
          </v-card>
          <v-card v-else>
            <v-card-title>
              {{ $t("tableTitle.list_lots") }}
            </v-card-title>
            <asset-table :items="currentLots"></asset-table>
          </v-card>
          <div style="text-align: right; font-size: small;">
            <br />
            {{ $t("assetDetails.text1") }}
          </div>
        </v-col>
      </v-row>
    </template>

    <template v-if="isMobile">
      <v-row class="mb-6 mx-n4" no-gutters>
        <v-col class="mt-5">
          <v-card>
            <v-row no-gutters class="pa-2">
              <v-col cols="9">
                <v-card-title class="headline display-1">
                  Documents
                </v-card-title>
              </v-col>
              <v-col cols="3" md="6" class="mt-3 pr-2">
                <v-row justify="center">
                  <v-dialog v-model="docFilterDialog" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on">
                        {{ $t("tableStatus.filter") }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{
                          $t("tableStatus.filter")
                        }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-select
                                :label="$t('tableStatus.by_name')"
                                :items="docNames"
                                clearable
                                v-model="selectedNames"
                                multiple
                                chips
                              ></v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                :label="$t('tableStatus.by_category')"
                                :items="docCategories"
                                clearable
                                v-model="selectedCategories"
                                multiple
                                chips
                              ></v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-daterange
                                v-model="dateRange"
                                display-format="dd-MM-yyyy"
                                no-title
                                :menu-props="menuProps"
                                :presets="presets"
                              ></v-daterange>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="docFilterDialog = false"
                          >OK
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10" class="mt-n4">
                <v-text-field
                  cols="8"
                  v-model="search"
                  append-icon="search"
                  :label="$t('tableStatus.searchName')"
                  single-line
                  hide-details
                  class="mt-n2"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-list style="max-height: 640px; overflow: auto">
              <v-list-item-group color="primary">
                <template v-if="loadingDoc">
                  <v-list-item v-for="(item, i) in 2" :key="i">
                    <v-list-item-content>
                      <v-skeleton-loader
                        ref="skeleton"
                        type="list-item-avatar-two-line"
                        class="mx-auto"
                      ></v-skeleton-loader>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-else>
                  <v-list-item v-for="item in docList" :key="item.id">
                    <v-list-item-avatar>
                      <v-icon large color="primary">{{
                        documentIcon(item.mime)
                      }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.name"
                        @click="openFile(item)"
                        :title="$t('assetDetails.text2')"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.documentcategories_id !== 0"
                        v-text="item.documentcategories_id"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-text="
                          moment(
                            item.date_creation,
                            'YYYY-MM-Do HH:mm ss'
                          ).format(' Do MMM YYYY, HH:mm')
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="primary"
                        @click="downloadFile(item)"
                        :title="$t('assetDetails.text3')"
                      >
                        <v-icon dark>mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters align="start" class="fill-height mx-n4">
        <v-col>
          <comment-editor :comment="note" :title="$t('assetDetails.text4')">
          </comment-editor>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row class="mb-6" no-gutters>
        <v-col md="4" class="mt-5">
          <v-card>
            <v-row no-gutters class="pa-2">
              <v-col cols="6">
                <v-card-title>Documents</v-card-title>
              </v-col>
              <v-col cols="3" md="6" class="mt-3 pr-2">
                <v-row justify="center">
                  <v-dialog v-model="docFilterDialog" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on">
                        {{ $t("tableStatus.filter") }}
                      </v-btn>
                    </template>
                    <v-row justify="center" no-gutters>
                      <v-col cols="4">
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{
                              $t("tableStatus.filter")
                            }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-select
                                    :label="$t('labels.per_name')"
                                    :items="docNames"
                                    clearable
                                    v-model="selectedNames"
                                    multiple
                                    chips
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-select
                                    :label="$t('tableStatus.by_category')"
                                    :items="docCategories"
                                    clearable
                                    v-model="selectedCategories"
                                    multiple
                                    chips
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-daterange
                                    v-model="dateRange"
                                    display-format="dd-MM-yyyy"
                                    no-title
                                    :menu-props="menuProps"
                                    :presets="presets"
                                  ></v-daterange>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="docFilterDialog = false"
                              >OK</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-dialog>
                </v-row>
              </v-col>
              <v-row justify="center">
                <v-col cols="10" class="mt-n4">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="$t('tableStatus.searchName')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>

            <v-list style="max-height: 640px; overflow: auto">
              <v-list-item-group color="primary">
                <template v-if="loadingDoc">
                  <v-list-item v-for="(item, i) in 2" :key="i">
                    <v-list-item-content>
                      <v-skeleton-loader
                        ref="skeleton"
                        type="list-item-avatar-two-line"
                        class="mx-auto"
                      ></v-skeleton-loader>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-else>
                  <v-list-item v-for="item in docList" :key="item.id">
                    <v-list-item-avatar>
                      <v-icon large color="primary">{{
                        documentIcon(item.mime)
                      }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.name"
                        @click="openFile(item)"
                        :title="$t('assetDetails.text2')"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.documentcategories_id !== 0"
                        v-text="item.documentcategories_id"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-text="
                          moment(
                            item.date_creation,
                            'YYYY-MM-Do HH:mm ss'
                          ).format(' Do MMM YYYY, HH:mm')
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="primary"
                        @click="downloadFile(item)"
                        :title="$t('assetDetails.text3')"
                      >
                        <v-icon dark>mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-btn
            class="my-2"
            elevation="1"
            @click="clicked"
            target="_blank"
            :title="$t('assetDetails.text5')"
          >
            <v-icon color="primary" class="mr-2" large>
              mdi-form-dropdown </v-icon
            >{{ $t("assetDetails.text6") }}
          </v-btn>
        </v-col>
        <v-col class="pt-5 ml-12">
          <comment-editor
            :comment="note"
            :title="$t('assetDetails.text4')"
          ></comment-editor>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MapView from "../components/Map";
import CommentEditor from "../components/editor/CommentEditor";
import VDaterange from "../components/VDaterange";
import AssetTable from "../components/AssetTable";
import AssetTableCcct from "../clients/AssetTableCCCT";
import USER_API from "../api/user";
import moment from "../plugins/moment";
import { permissions } from "../utils";

export default {
  name: "AssetDetails",
  components: {
    MapView,
    CommentEditor,
    VDaterange,
    AssetTable,
    AssetTableCcct
  },
  data() {
    return {
      documentsList: [],
      loadingDoc: false,
      comment: "",
      activeEntity: null,
      note: null,
      docFilterDialog: false,
      selectedNames: [],
      selectedCategories: [],
      search: "",
      dateRange: {},
      menuProps: { offsetY: true, closeOnContentClick: false },
      presets: [
        {
          label: this.$t("index.today"),
          range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
        },
        {
          label: this.$t("index.yesterday"),
          range: [
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          label: this.$t("index.last_days"),
          range: [
            moment()
              .subtract(30, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        }
      ]
    };
  },
  //mixins: [AssetsData],
  computed: {
    ...mapGetters([
      "getTreeData",
      "getOccupants",
      "getDocumentsImages",
      "getUserProfiles",
      "getAssets",
      "isAdminUser",
      "getUserId"
    ]),
    currentAsset() {
      const assetId = this.$route.params.id;
      const asset = this.getAssets.find(asset => {
        return +asset.id === +assetId;
      });

      return asset ? asset : null;
    },
    currentLots() {
      const lots = this.currentAsset.children.filter(
        entity => entity.partiescommunefield !== 2 // CCA 2 pour afficher les PC
      );
      const keys = lots.map(el => el.id);
      return this.getAssets.filter(asset => {
        return keys.includes(asset.id);
      });
    },
    latLng() {
      const latLng = this.currentAsset
        ? this.currentAsset.etageportefield
        : "0/0";
      const data = latLng ? latLng.split("/") : [0, 0];
      return {
        lat: data[0],
        lng: data[1]
      };
    },
    items() {
      const lots = this.currentAsset ? this.currentAsset.children : [];

      return lots.map(entity => {
        const occupant = this.getOccupants.find(el => el.items_id == entity.id);
        if (occupant) {
          entity.surfacemtwofield = occupant.surfacemtwofield;
          entity.nomcommercialfield = occupant.nomcommercialfield;
          entity.etageportefield = occupant.etageportefield;
          entity.datedbutdebailfield = occupant.datedbutdebailfield;
          entity.datefindebailfield = occupant.datefindebailfield;
          entity.datelimitedersiliationfield =
            occupant.datelimitedersiliationfield;
          entity.loyerannuelhtfield = occupant.loyerannuelhtfield;
        }
        return entity;
      });
    },
    images() {
      const assetId = this.$route.params.id;
      const assetImages = this.getDocumentsImages.filter(
        el => el.entities_id === +assetId
      );
      return assetImages;
    },
    docNames() {
      return this.documentsList.map(doc => doc.name);
    },
    docCategories() {
      let categories = this.documentsList.map(doc => {
        if (doc.documentcategories_id === 0) {
          return "Sans Catégorie";
        }
        return doc.documentcategories_id;
      });
      return [...new Set(categories)];
    },
    authorizedDocs() {
      return this.documentsList.filter(doc => {
        const comment = "" + doc.comment;
        const per = comment.substring(0, 3);
        return this.checkDocPermission(per);
      });
    },
    filteredDocList() {
      return this.authorizedDocs.filter(doc => {
        if (doc.documentcategories_id === 0) {
          doc.documentcategories_id = "Sans Catégorie";
        }
        const dateCreation = moment(
          doc.date_creation,
          "YYYY-MM-Do HH:mm ss"
        ).format("YYYY-MM-DD");

        return (
          (this.selectedNames.length === 0 ||
            this.selectedNames.includes(doc.name)) &&
          (this.selectedCategories.length === 0 ||
            this.selectedCategories.includes(doc.documentcategories_id)) &&
          this.filterByDate(dateCreation)
        );
      });
    },
    docList() {
      if (!this.search) return this.filteredDocList;
      return this.filteredDocList.filter(doc => {
        if (doc.documentcategories_id === 0)
          doc.documentcategories_id = "Sans Catégorie";

        return (
          doc.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          doc.documentcategories_id
            .toLowerCase()
            .search(this.search.toLowerCase()) > -1
        );
      });
    },
    locationList() {
      const size = this.currentLots.length;
      let isGps = 0;
      for (let i = 0; i < size; i++) {
        if (
          this.currentLots[i].etageportefield &&
          this.currentLots[i].etageportefield.includes("/")
        ) {
          isGps = 1;
        }
      }
      if (isGps === 1) {
        return this.currentLots
          .filter(el => el.level === 5)
          .map(el => {
            const latlng = this.getLatLng(el);
            return {
              name: el.name,
              lat: latlng.lat,
              lng: latlng.lng,
              link: { name: "lots.show", params: { id: el.id } }
            };
          });
      } else {
        return [
          {
            name: this.currentAsset.name,
            lat: this.getLatLng(this.currentAsset).lat,
            lng: this.getLatLng(this.currentAsset).lng
          }
        ];
      }
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    isCcct() {
      return (
        this.$route.params.assetID === 211 ||
        this.$route.params.assetID === 214 ||
        //this.getUserId === 81 ||
        //this.getUserId === 69 ||
        this.getUserId === 80
      );
    },
    isInfo() {
      return this.getUserProfiles[0] === 22;
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    moment,
    getEntityParentName(entity) {
      let parent = this.getEntity.find(ent => ent.id == entity.entities_id);
      return parent ? parent.name : "";
    },
    clicked() {
      let routeData = this.$router.resolve({ name: "view.attestation" });
      window.open(routeData.href, "_blank");
    },
    loadData() {
      this.loadingDoc = true;
      USER_API.setActiveEntity(this.$route.params.id).then(() => {
        USER_API.getActiveEntity().then(({ data }) => {
          this.activeEntity = data;
        });
        USER_API.loadDoc()
          .then(response => {
            const docs = response.data;
            this.documentsList = docs;
            /*.filter(
              doc => doc.entities_id === this.$route.params.id
            );*/
          })
          .finally(() => {
            this.loadingDoc = false;
          });
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (this.isAdminUser === true) {
                //partage des notes entre 34 et 14
                if (
                  data[i].content.substring(0, 5) === "00034" ||
                  data[i].content.substring(0, 5) === "00014"
                ) {
                  this.note = data[i].content.substring(5);
                  break;
                }
              } else if (
                data[i].content.substring(0, 5) ===
                this.getUserId.toString().padStart(5, "0")
              ) {
                this.note = data[i].content.substring(5);
                break;
              } else {
                for (let i = 0; i < size; i++) {
                  if (data[i].content.substring(0, 5) === "00000") {
                    this.note = data[i].content.substring(5);
                    break;
                  } else {
                    this.note = "Pas de note enregistrée";
                  }
                }
              }
            }
          } else {
            this.note = null;
          }
        });
      });
    },
    documentIcon(mimeType) {
      if (mimeType === "image/jpeg") {
        return "mdi-folder-image";
      } else if (mimeType === "application/pdf") {
        return "mdi-file-pdf";
      } else {
        return "mdi-folder";
      }
    },
    downloadFile(item) {
      USER_API.downloadDocument(item);
    },
    openFile(item) {
      USER_API.openDocument(item);
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date).format("DD-MM-YYYY");
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    filterByDate(date) {
      if (!this.dateRange.start && !this.dateRange.end) return true;
      return this.dateRange.start <= date && this.dateRange.end >= date;
    },
    checkDocPermission(permission) {
      const permissionSize = permission.length;
      if (permissionSize > 2) {
        const publique = permission[0];
        const occupant = permission[1];
        const investisseur = permission[2];

        let per = false;
        if (publique.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "publique",
            this.getUserProfiles
          );
          if (per) return true;
        }

        if (occupant.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "occupant",
            this.getUserProfiles
          );
          if (per) return true;
        }
        if (investisseur.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "investisseur",
            this.getUserProfiles
          );

          if (per) return true;
        }

        return false;
      }
      return false;
    },
    getLatLng(el) {
      const latLng = el ? el.etageportefield : "0/0";
      const data = latLng ? latLng.split("/") : [0, 0];
      return {
        lat: data[0],
        lng: data[1]
      };
    }
  },
  watch: {
    $route() {
      this.loadData();
    }
  }
};
</script>
